@import '~antd/dist/antd.less';
@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

/* [FORM]  */

// HINT : 폼 아이템
.ant-form-item {
  margin-bottom: 20px;

  .ant-form-item-explain {
    min-height: 12px;
    position: absolute;
    bottom: -16px;
  }
}

// HINT : 날짜 선택

.ant-picker-range .ant-picker-input > input {
  text-align: center;
}

// HINT : 필수 항목 별표
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #00a74c;
  margin-right: 0;
}

// HINT : 에러 문구
.ant-form-item-explain-error {
  font-size: 10px;
}

// HINT : 텍스트 박스 글자 수 카운트
.ant-form-item .ant-input-textarea-show-count::after {
  font-size: 10px;
}

/* [TABLE] */

// HINT : 테이블 페이지네이션 정렬
.ant-table-pagination-right {
  justify-content: center;
}

/* [LIST] */

// HINT : 테이블 페이지네이션 정렬
.ant-list-pagination {
  text-align: center;
}

.ant-pagination-options {
  display: none;
}

/* [Typography] */

// HINT : margin-bottom 제거

h1.ant-typography {
  margin-bottom: 0;
}

/* [Modal] */

// HINT : 모달 icon 비노출
.ant-modal-confirm .anticon-info-circle {
  display: none;
}

// HINT : 모달 body 왼쪽의 마진 제거
.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0;
}

// HINT : info 모달의 경우 확인 버튼 disable
.ant-modal-confirm.ant-modal-confirm-info .ant-modal-confirm-btns {
  display: none;
}

@primary-color: #00a74c;